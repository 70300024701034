<script setup lang="ts">
import { computed, toValue } from 'vue';
import strings from '../../../../settings-strings';

import {
  WsSelect,
  WsSelectOption,
  WsToggle,
  WsButton,
} from '@mfl/common-components';

import {
  ensureWrappedWithBraces,
  isInBraces,
  stripBraces,
} from '../../../../settings-utils';

const props = defineProps<{
  options: Array<{
    options: Array<{
      value: string;
      label: string;
    }>;
  }>;
  disabled: boolean;
  toggleAid: string;
  inputAid: string;
  selectAid: string;
}>();

const model = defineModel<string>({
  required: true,
});

const localValue = computed<{
  value: string;
  useVariable: boolean;
}>({
  get() {
    const useVariable = isInBraces(model.value);
    return {
      value: stripBraces(model.value),
      useVariable,
    };
  },
  set(v) {
    model.value = v.useVariable
      ? ensureWrappedWithBraces(v.value)
      : stripBraces(v.value);
  },
});

let beforeToggleValue = '';

const localOptions = computed(() =>
  props.options.map(({ options }) => {
    return {
      options: options.map((o) => o.value),
    };
  })
);

const localLabels = computed(() =>
  props.options.reduce(
    (acc, { options }) => {
      return options.reduce((acc, o) => {
        acc[o.value] = o.label;
        return acc;
      }, acc);
    },
    { '{{}}': strings.select, '': strings.select } as Record<string, string>
  )
);

const handleUseVariableToggle = (v: boolean) => {
  const oldValue = toValue(localValue).value;
  if (v) {
    localValue.value = {
      useVariable: true,
      value: beforeToggleValue,
    };
  } else {
    localValue.value = {
      value: beforeToggleValue,
      useVariable: v,
    };
  }
  beforeToggleValue = oldValue;
};

const handleSelectChange = (v: unknown) => {
  localValue.value = {
    ...localValue.value,
    value: v as string,
  };
};
</script>

<template>
  <div class="logo-field-select-container">
    <div class="logo-field-select-content">
      <template v-if="localValue.useVariable">
        <div class="logo-field-select-header">
          <div class="logo-field-select-spacer"></div>
          <div class="logo-field-select-toggle">
            <p class="logo-toggle-label">
              {{ strings.employeePortalBrandingFieldToggleLabel }}
            </p>
            <ws-toggle
              v-model:model-value="localValue.useVariable"
              :aid="props.toggleAid"
              :disabled="props.disabled"
              @update:model-value="handleUseVariableToggle"
            />
          </div>
        </div>
        <div class="logo-field-select-control">
          <ws-select
            :model-value="localValue.value"
            :option-label="(v) => localLabels[v]"
            :option-key="(v) => v"
            :aid="props.selectAid"
            :disabled="props.disabled"
            @update:model-value="handleSelectChange"
          >
            <template v-for="group in localOptions" :key="group">
              <template v-if="group.options?.length > 0">
                <WsSelectOption
                  v-for="opt in group.options"
                  :key="opt"
                  :value="opt"
                >
                  {{ localLabels[opt] }}
                </WsSelectOption>
              </template>
            </template>
          </ws-select>
        </div>
      </template>

      <template v-else>
        <div class="logo-field-select-inline">
          <ws-button
            v-model="model"
            :disabled="props.disabled"
            :label="strings.employeePortalBrandingUploadImagesBtn"
            :aid="props.inputAid"
            size="md"
            class="upload-button"
          />
          <div class="logo-field-select-toggle">
            <p class="logo-toggle-label">
              {{ strings.employeePortalBrandingFieldToggleLabel }}
            </p>
            <ws-toggle
              v-model:model-value="localValue.useVariable"
              :aid="props.toggleAid"
              :disabled="props.disabled"
              @update:model-value="handleUseVariableToggle"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.logo-field-select-container {
  width: 100%;

  .logo-field-select-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }

  .logo-field-select-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .logo-field-select-spacer {
      flex: 1;
    }
  }

  .logo-field-select-inline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .logo-field-select-toggle {
    display: flex;
    align-items: center;
    gap: 8px;

    .logo-toggle-label {
      font-size: 14px;
      color: var(--text-secondary);
      margin: 0;
    }
  }

  .logo-field-select-control {
    width: 100%;

    :deep(.ws-select) {
      width: 100%;
      max-width: 320px;
    }
  }

  .upload-button {
    width: fit-content;
  }
}
</style>
