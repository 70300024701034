<script setup lang="ts">
import { onMounted, computed } from 'vue';
import { currentUser } from '@mfl/platform-shell';
import WsInfo from '../../components/info/ws-info.vue';
import WsSection from '../../components/section/ws-section.vue';
import SaveButton from '../../components/button/save-button.vue';

import EmployeePortalSettings from './components/employee-portal-settings.vue';
import EmployeePortalBranding from './components/employee-portal-branding.vue';

import strings from '../../settings-strings';

import employeePortalSettings from './domain-employees';
import { UserEntitlements } from '../../settings-types';

const isBrandingEnabled = computed(() =>
  currentUser.isEntitledTo(UserEntitlements.EMPLOYEE_HUB_BRANDING)
);

const {
  state,
  onChange,
  triggerFetch,
  triggerUpdate,
  model,
  validationErrors,
} = employeePortalSettings;

import generalDomainInfo from '../../composables/domain-general-info';

const handleSaveChanges = () => {
  triggerUpdate();
};

onMounted(() => {
  triggerFetch();
});
</script>
<template>
  <ws-section :is-loading="state.isLoading">
    <template #header>
      <div class="employee-portal-header-text-container">
        {{ strings.employeePortal }}
        <WsInfo
          :tooltip="strings.employeePortalTitleTooltip"
          tooltip-position="right"
          aid="EMPLOYEE_PORTAL_TITLE_TOOLTIP"
        />
      </div>
    </template>
    <template #body>
      <EmployeePortalSettings
        :model-value="model"
        :domain-id="generalDomainInfo.data.id"
        :admin-employee="generalDomainInfo.data.adminEmployee"
        :errors="validationErrors"
        @update:model-value="onChange"
      />

      <EmployeePortalBranding
        v-if="isBrandingEnabled"
        :model-value="model"
        :domain-id="generalDomainInfo.data.id"
        :admin-employee="generalDomainInfo.data.adminEmployee"
        :domain-data="generalDomainInfo.data.domainData"
        :errors="validationErrors"
        @update:model-value="onChange"
      />
    </template>

    <template #actions>
      <save-button
        aid="SAVE_EMPLOYEE_PORTAL_SETTINGS_BUTTON"
        v-bind="state"
        @save-changes="handleSaveChanges"
      />
    </template>
  </ws-section>
</template>
<style>
.employee-portal-card-section {
  display: flex;
  justify-content: space-between;
}

.employee-portal-header-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
</style>
