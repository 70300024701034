<script setup lang="ts"></script>
<template>
  <div class="ws-card">
    <div class="ws-card-body">
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
:root {
  --card-controls-flex-direction: column;
  --card-section-body-flex-direction: row;
  --card-section-body-flex-gap: 24px;
}

.ws-card {
  display: flex;
  flex-direction: var(--card-controls-flex-direction);
  gap: 10px;
  width: 100%;

  .ws-card-header {
    color: var(--Gray-500, #2d2e30);

    font-family: Mulish;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
  }

  .ws-card-body {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 20px;
    padding: 24px;
    width: 100%;

    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);

    border-radius: 8px;
    vertical-align: top;
    background: #fff;

    .ws-section-separator {
      margin-left: 16px;
      margin-right: 16px;
      display: block;
      height: 1px;
      border: none;
      background: rgba(0, 0, 0, 0.12);
      margin: 0;
      flex-shrink: 0;
    }

    .ws-card-section {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .ws-card-section-title {
        color: var(--Gray-400, #191c2b);
        font-family: Mulish;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        display: flex;
        justify-content: space-between;
      }

      .ws-card-section-body {
        margin: 0;
        padding: 0;

        display: flex;
        flex-direction: var(--card-section-body-flex-direction);
        align-items: flex-start;
        align-content: flex-start;
        justify-content: space-between;
        flex-grow: 1;
        gap: var(--card-section-body-flex-gap);

        .ws-card-section-desc {
          color: var(--Gray-400, #191c2b);
          font-family: Mulish;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;

          width: 100%;
        }

        .ws-card-section-control {
          width: 100%;
        }
      }
    }
  }
}

.card-title,
.card-title-p {
  color: #2d2e30;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding-bottom: 14px;
}

.card-title-p {
  padding-top: 20px;
}

@media only screen and (max-width: 1024px) {
  :root {
    --card-section-body-flex-direction: row;
  }
}

@media only screen and (max-width: 748px) {
  :root {
    --card-section-body-flex-direction: column;
  }
}
</style>
