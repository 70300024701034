import { openDialog } from '@mfl/common-components';
import RemoveIntegrationDialog from './remove-integration-dialog.vue';

import strings from '../../../../settings-strings';

export type RemoveIntegrationDialogInput = void;

export type RemoveIntegrationDialogOutput = void;

export const openRemoveIntegrationDialog = async (
  input: RemoveIntegrationDialogInput
): Promise<RemoveIntegrationDialogOutput> => {
  await openDialog<RemoveIntegrationDialogInput, RemoveIntegrationDialogOutput>(
    {
      aid: 'REMOVE_INTEGRATION_DIALOG',
      headerText: strings.confirmRemoveIntegrationPromptTitle,
      showTopSeparator: false,
      component: RemoveIntegrationDialog,
      input,
    }
  );
};
