<script setup lang="ts">
import { WsButton } from '@mfl/common-components';
import { currentUser } from '@mfl/platform-shell';
import { Organization } from '@msl/user-gateway-sdk';
import { UserPermissions } from '../../../settings-types';
import EditOrganizationName from './controls/edit-organization-name.vue';
import EditDomainName from './controls/edit-domain-name.vue';
import EmailServiceSelect from './controls/email-service-select.vue';
import TimezoneSelect from './controls/timezone-select.vue';
import RemoveOrganization from './controls/remove-organization.vue';

import strings from '../../../settings-strings';

import {
  DomainBasicInfoModel,
  GeneralDomainInfo,
} from '../../../settings-types';

import WsCard from '../../../components/card/ws-card.vue';
import WsCardSection from '../../../components/card/ws-card-section.vue';
import WsCardSectionSeparator from '../../../components/card/ws-card-section-separator.vue';
import { computed, DeepReadonly } from 'vue';
import { IntegrationType } from '@msl/settings-gateway-sdk';

const props = defineProps<{
  modelValue: DomainBasicInfoModel;
  domainGeneralInfo: DeepReadonly<GeneralDomainInfo>;
  errors: Record<string, string | undefined>;
  organizations: Array<Organization>;
}>();

const emit = defineEmits([
  'remove-domain-integration',
  'remove-organization',
  'update:modelValue',
]);

const handleUpdateDomainName = (name: string) => {
  emit('update:modelValue', {
    ...props.modelValue,
    domain: name,
  });
};

const handleUpdateOrganizationName = (name: string) => {
  emit('update:modelValue', {
    ...props.modelValue,
    organizationName: name,
  });
};

const handleUpdateDomainIntegrationType = (
  integrationType: IntegrationType
) => {
  emit('update:modelValue', {
    ...props.modelValue,
    integrationType,
  });
};

const handleUpdateDomainTimezone = (tz: string) => {
  emit('update:modelValue', {
    ...props.modelValue,
    tz,
  });
};

const connectedBy = computed(() => {
  return {
    type: props.domainGeneralInfo.integrationType,
    value: props.domainGeneralInfo.connectedBy,
  };
});

const hadMoreThanOneOrganization = computed(() => {
  return props.organizations.length > 1;
});
</script>

<template>
  <ws-card>
    <ws-card-section>
      <template #title>{{ strings.organizationName }}</template>
      <template #desc>{{ strings.organizationNameDescr }}</template>
      <template #control>
        <EditOrganizationName
          :model-value="modelValue.organizationName"
          :error="props.errors['organizationName']"
          @update:model-value="handleUpdateOrganizationName"
        />
      </template>
    </ws-card-section>
    <ws-card-section-separator />
    <ws-card-section>
      <template #title>{{ strings.emailService }}</template>
      <template #desc>{{ strings.domainEmailServiceDescr }}</template>
      <template #control>
        <email-service-select
          :model-value="modelValue.integrationType"
          :is-integrated="props.domainGeneralInfo.isIntegrated"
          :marketplace-link="props.domainGeneralInfo.marketplaceLink"
          :domain-id="props.domainGeneralInfo.id"
          @update:model-value="handleUpdateDomainIntegrationType"
        />
      </template>
    </ws-card-section>
    <ws-card-section-separator />
    <ws-card-section>
      <template #title>
        <div>{{ strings.domainUrl }}</div>
        <WsButton
          v-if="props.domainGeneralInfo.isIntegrated"
          aid="EMAIL_SERVISE_REMOVE_INTEGRATION_BTN"
          class="text-underline"
          color="gray-400"
          :label="strings.remove"
          variant="text"
          @click="emit('remove-domain-integration')"
        />
      </template>
      <template #desc>{{ strings.domainGeneralDescr }}</template>
      <template #control>
        <edit-domain-name
          :model-value="modelValue.domain"
          :error="props.errors['domain']"
          :is-integrated="props.domainGeneralInfo.isIntegrated"
          :connected-by="connectedBy"
          @update:model-value="handleUpdateDomainName"
        />
      </template>
    </ws-card-section>
    <ws-card-section-separator />
    <ws-card-section>
      <template #title>{{ strings.timeZone }}</template>
      <template #desc>{{ strings.domainTimeZoneDescr }}</template>
      <template #control>
        <timezone-select
          :model-value="modelValue.tz"
          @update:model-value="handleUpdateDomainTimezone"
        />
      </template>
    </ws-card-section>
    <template
      v-if="
        currentUser.isAllowedTo(UserPermissions.ACCOUNT_MANAGE) &&
        hadMoreThanOneOrganization
      "
    >
      <ws-card-section-separator />
      <ws-card-section>
        <template #title>{{ strings.removeOrganizationTitle }}</template>
        <template #desc>{{ strings.removeOrganizationDescription }}</template>
        <template #control>
          <remove-organization
            @remove-organization="emit('remove-organization')"
          />
        </template>
      </ws-card-section>
    </template>
  </ws-card>
</template>
