import { useQuasarPlugin } from '@mfl/common-components';
import { bootstrapMicrofrontend } from '@mfl/vue-common';

import mfe from './settings-mfe.vue';
import { createSettingsRouter } from './sections/sections';
import { organizationApi } from '@msl/user-gateway-sdk';
import { auth4Sdks } from '@mfl/platform-shell';

const { bootstrap, mount, unmount } = bootstrapMicrofrontend({
  name: 'settings',
  mainComponent: mfe,
  targetElement: '#content',
  includeCss: true,
  handleVueApp: (app) => {
    organizationApi.init(auth4Sdks());
    useQuasarPlugin(app);

    app.use(createSettingsRouter());
  },
});

export { bootstrap, mount, unmount };
