<script setup lang="ts">
import { WsButton } from '@mfl/common-components';
import strings from '../../../../settings-strings';

const emit = defineEmits(['remove-organization']);
</script>
<template>
  <div class="remove-organization-control">
    <ws-button
      aid="REMOVE_ORGANIZATION_LINK"
      :label="strings.removeOrganizationLinkTitle"
      size="md"
      variant="text"
      color="danger"
      @click="emit('remove-organization')"
    />
  </div>
</template>
<style lang="css" scoped>
.remove-organization-control {
  display: flex;
  justify-content: flex-end;
}
</style>
