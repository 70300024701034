<script setup lang="ts">
import { onMounted, ref, computed, toValue } from 'vue';
import {
  DepartmentsDialogInput,
  DepartmentsDialogOption,
} from './departments-dialog';
import strings from '../../../../settings-strings';
import { openChangeSpecificatorCategoryDialog } from '../change-specificator-category-dialog/change-specificator-category-dialog';
import { openRemoveEmployeesDialog } from '../remove-employees-dialog/remove-employees-dialog';
import {
  IDialog,
  WsDialogSection,
  WsDialogActions,
  WsButton,
} from '@mfl/common-components';

import runSyncModel from '../../../../composables/run-sync-model';
import longActionModel from '../../../../composables/long-action-model';
import specificatorsModel from '../../../../composables/specificators-model';
import domainSyncAutomation from '../../../../composables/domain-sync-automation';
import generalDomainInfo from '../../../../composables/domain-general-info';
import { IntegrationType, Specificator } from '@msl/settings-gateway-sdk';
import ConfirmableRadioGroup from '../../../../components/radio-group/confirmable-radio-group.vue';
import FilteredList from '../../../../components/filtered-list/filtered-list.vue';
import DepartmentItem from './department-item.vue';

const { dialog } = defineProps<{
  dialog: IDialog<DepartmentsDialogInput>;
}>();

const selectedSpecificatorType = ref(
  domainSyncAutomation.model.value.sync.specificators.type
);

function getUnselectedSpecificators(
  selectedItems: Array<string>,
  oldSelectedItems: Array<string>
) {
  if (!oldSelectedItems.length) {
    return [];
  }

  return oldSelectedItems.filter((item) => !selectedItems.includes(item));
}

const specificatorTypeOptions = computed(() => {
  if (generalDomainInfo.data.integrationType === IntegrationType.GOOGLE) {
    return [
      {
        aid: 'RADIO_ORG_DEPARTMENT',
        label: strings.departmentTitle,
        value: 'orgDepartment',
      },
      {
        aid: 'RADIO_ORG_UNIT_PATH',
        label: strings.orgUnit,
        value: 'orgUnitPath',
      },
      {
        aid: 'RADIO_ORG_COST_CENTER',
        label: strings.costCenter,
        value: 'orgCostCenter',
      },
    ];
  }

  return [
    {
      aid: 'RADIO_DEPARTMENT',
      label: strings.departmentTitle,
      value: 'department',
    },
  ];
});

const showSpecificatorTypeSelect = computed(() => {
  const selectedType = toValue(selectedSpecificatorType);
  const isOneOfBasicSpecificators = !!toValue(specificatorTypeOptions).find(
    (opt) => opt.value === selectedType
  );
  return (
    isOneOfBasicSpecificators &&
    generalDomainInfo.data.integrationType === IntegrationType.GOOGLE &&
    generalDomainInfo.data.requireFinishIntegration
  );
});

const selectedSpecificatorLabel = computed(() => {
  const specificatorType = toValue(selectedSpecificatorType);
  const options = toValue(specificatorTypeOptions);

  if (specificatorType) {
    return (
      options.find((opt) => opt.value === specificatorType)?.label || ''
    ).toLowerCase();
  }
  return '';
});

const filteredSpecificatorsOptions = computed<Array<DepartmentsDialogOption>>(
  () => {
    const selectedSpecificator = specificatorsModel.data.specificators?.find(
      (s) => {
        return s.departmentName === selectedSpecificatorType.value;
      }
    );

    if (!selectedSpecificator) {
      throw new Error('No selected specificator');
    }

    const syncSelectedNames = toValue(selectedItems);

    return (
      selectedSpecificator.entries?.map((entry) => {
        const option: DepartmentsDialogOption = {
          aid: entry.name!.toUpperCase(),
          searchKey: entry.name!.toUpperCase(),
          data: {
            ...entry,
          },
          selected: syncSelectedNames.includes(entry.name!),
        };

        const isNone = entry.name === 'None';
        const isOrgUnit =
          !isNone && selectedSpecificatorType.value === 'orgUnitPath';
        if (isOrgUnit) {
          option.data.isOrgUnit = true;
        } else if (isNone) {
          option.special = true;
          option.data.isNone = true;
          option.data.title = selectedSpecificatorLabel.value;
        }
        return option;
      }) || []
    );
  }
);

const hasChanges = computed(() => {
  const selectedEntries = toValue(selectedItems).toSorted();
  const existingSelectedEntries = toValue(
    domainSyncAutomation.model
  ).sync.specificators.values.toSorted();
  if (selectedEntries.length !== existingSelectedEntries.length) {
    return true;
  }

  for (let i = 0; i < selectedEntries.length; i += 1) {
    if (selectedEntries[i] !== existingSelectedEntries[i]) {
      return true;
    }
  }

  return false;
});

const selectedItems = ref(
  domainSyncAutomation.model.value.sync.specificators.values
);

async function saveAndSyncHandler() {
  const unselectedSpecificators = getUnselectedSpecificators(
    selectedItems.value,
    domainSyncAutomation.model.value.sync.specificators.values
  );

  if (
    unselectedSpecificators.length &&
    !generalDomainInfo.data.requireFinishIntegration
  ) {
    const result = await openRemoveEmployeesDialog(
      String(unselectedSpecificators.length)
    );

    if (result && result == 'remove') {
      domainSyncAutomation.model.value.sync.specificators.type =
        selectedSpecificatorType.value;
      domainSyncAutomation.model.value.sync.specificators.values =
        selectedItems.value;
      await domainSyncAutomation.triggerUpdate();

      await runSyncModel.triggerFetch(unselectedSpecificators);
      await longActionModel.triggerFetch();
      dialog.close();
    } else if (result && result == 'keep') {
      domainSyncAutomation.model.value.sync.specificators.type =
        selectedSpecificatorType.value;
      domainSyncAutomation.model.value.sync.specificators.values =
        selectedItems.value;
      await domainSyncAutomation.triggerUpdate();
      await runSyncModel.triggerFetch([]);
      await longActionModel.triggerFetch();
      dialog.close();
    }
  } else {
    domainSyncAutomation.model.value.sync.specificators.type =
      selectedSpecificatorType.value;
    domainSyncAutomation.model.value.sync.specificators.values =
      selectedItems.value;
    await domainSyncAutomation.triggerUpdate();

    await runSyncModel.triggerFetch([]);
    await longActionModel.triggerFetch();
    await generalDomainInfo.triggerFetch();
    dialog.close();
  }
}

async function refreshSpecificatorsHandler() {
  await specificatorsModel.triggerFetch(true);
  await longActionModel.triggerFetch();
  dialog.close();
}

const confirmSpecificatorTypeChange = async (newVal: string) => {
  const result = await openChangeSpecificatorCategoryDialog(
    getSpecificatorTypeTitle(newVal)
  );

  if (result) {
    selectedItems.value = [];
    selectedSpecificatorType.value = newVal;
  }

  return result;
};

const handleSelectionChange = (selectedEntries: Array<Specificator>) => {
  selectedItems.value = selectedEntries.map((e) => e.name!);
};

const getSpecificatorTypeTitle = (val: string) => {
  const specificatorType = val;
  const options = toValue(specificatorTypeOptions);

  if (specificatorType) {
    return (
      options.find((opt) => opt.value === specificatorType)?.label || ''
    ).toLowerCase();
  }
  return '';
};

onMounted(async () => {});
</script>
<template>
  <ws-dialog-section>
    <ConfirmableRadioGroup
      v-if="showSpecificatorTypeSelect"
      v-model="selectedSpecificatorType"
      aid="SPECIFICATORS_RADIO_GROUP"
      :options="specificatorTypeOptions"
      :confirm="confirmSpecificatorTypeChange"
    />
    <FilteredList
      aid="SPEFICATORS_FILTERED_LIST"
      :items="filteredSpecificatorsOptions"
      :item-component="DepartmentItem"
      @selection-change="handleSelectionChange"
    />
  </ws-dialog-section>
  <ws-dialog-actions>
    <ws-button
      :label="strings.refreshListButton"
      aid="REFRESH_LIST_BUTTON"
      variant="outlined"
      color="gray-500"
      @click="refreshSpecificatorsHandler()"
    />
    <ws-button
      :label="strings.saveAndSyncButton"
      aid="SAVE_AND_SYNC_BUTTON"
      color="primary"
      :disabled="!hasChanges"
      @click="saveAndSyncHandler()"
    />
  </ws-dialog-actions>
</template>
<style scoped>
.specificator-type-select-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  width: 520px;
  padding-bottom: 14px;
}
</style>
