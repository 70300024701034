<script setup lang="ts">
import { computed } from 'vue';
import { navigateTo } from '@mfl/framework';

const props = defineProps<{
  aid: string;
  class?: string;
  icons: Array<string>;
  route?: string;
  text: string;
  collapsed: boolean;
  disabled?: boolean;
  active?: boolean;
}>();

const handleClick = (event: MouseEvent) => {
  if (props.disabled) {
    event.preventDefault();
    return;
  }

  if (props.route) {
    event.preventDefault();
    navigateTo(props.route);
  }
};

const className = computed(() => {
  const cls = 'side-menu-link';
  const collapsedCls = props.collapsed ? `${cls}-collapsed` : '';
  const disabledCls = props.disabled ? `${cls}-disabled` : '';
  const activeCls = props.active ? `${cls}-active` : '';

  return `${props.class || ''} ${cls} ${collapsedCls} ${disabledCls} ${activeCls}`;
});
</script>
<template>
  <a
    :aid="props.aid"
    :class="className"
    :href="props.route"
    @click="handleClick"
  >
    <span class="link-icon-container">
      <i v-for="icon in props.icons" :key="icon" :class="icon"></i>
    </span>
    <span
      class="link-text-container text-md font-normal"
      :data-text="props.text"
    >
      {{ props.text }}
    </span>
  </a>
</template>
<style lang="scss">
:root {
  --font-weight: 400;
  --font-weight-hover: 600;
  --font-weight-active: 600;

  --text-color: #2d2e30;
  --text-color-disabled: #e0e0e0;
  --item-text-width: 148px;

  --item-h-padding: 14px;
  --item-v-padding: 10px;

  --item-background-color-active: var(--Primary-300, #e1ebff);
  --item-background-color-hover: var(--Primary-50, #f4f6f8);
}

.side-menu-link {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  gap: 8px;

  padding: var(--item-v-padding) var(--item-h-padding);
  margin: 0;

  border-radius: 50px;

  background-color: transparent;

  width: 100%;
  height: 100%;

  color: var(--text-color);

  &.side-menu-link-active,
  &:hover {
    .link-icon-container {
      i:first-child {
        display: none;
      }
      i:last-child {
        display: inline-block;
      }
    }

    .link-text-container {
      font-weight: var(--font-weight-active);
    }
  }

  &:hover {
    background-color: var(--item-background-color-hover);
  }

  &.side-menu-link-active,
  &.side-menu-link-active:hover {
    background-color: var(--item-background-color-active);
  }

  &.side-menu-link-collapsed {
    gap: 0;
    width: fit-content;
    .link-icon-container {
      margin-right: 0;
    }
    .link-text-container {
      width: 0;
      display: none;
      &::before {
        width: 0;
        display: none;
      }
    }
  }

  &.side-menu-link-disabled {
    cursor: not-allowed;
    color: var(--text-color-disabled);
  }

  i:first-child {
    display: inline-block;
  }

  i:last-child {
    display: none;
  }

  .link-icon-container {
    display: inline-block;
  }

  .link-text-container {
    display: inline-block;

    font-size: 14px;
    line-height: 20px;

    max-width: var(--item-text-width);
    width: var(--item-text-width);

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &::before {
      max-width: var(--item-text-width);
      width: var(--item-text-width);
      content: attr(data-text);
      display: block;
      font-weight: var(--font-weight-active);
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }
}
</style>
