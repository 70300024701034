import { prompt, PromptResult } from '@mfl/common-components';
import { navigateTo } from '@mfl/framework';
import strings from '../../../settings-strings';
import { removeOrganization } from '../../../composables/organizations';

export const openRemoveOrganizationPrompt = async () => {
  const result = await prompt({
    aid: 'REMOVE_ORGANIZATION_DIALOG',
    header: strings.removeOrganizationModalTitle,
    question: strings.removeOrganizationModalText,
    width: '500px',
    separators: 'bottom',
    primaryButtonText: strings.removeOrganizationModalConfirmButton,
    primaryButtonColor: 'danger',
    secondaryButtonText: strings.removeOrganizationModalCancelButton,
  });

  if (result == PromptResult.Primary) {
    await removeOrganization.triggerFetch();
    sessionStorage.removeItem('domainKey');
    sessionStorage.removeItem('domainId');
    navigateTo('/v1/settings/domain', { reload: true });
  }
};
