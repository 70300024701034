import {
  organizationApi,
  RemoveOrganizationResponse,
} from '@msl/user-gateway-sdk';
import { Organization } from '../settings-types';
import { useRequest } from './use-request';

const organizationsList = useRequest<Array<Organization>, void>(async () => {
  const res = await organizationApi.getOrganizationsList({});
  return res.result || [];
}, []);

const removeOrganization = useRequest<RemoveOrganizationResponse, string>(
  () => organizationApi.removeOrganization({}),
  {}
);

export { organizationsList, removeOrganization };
