import {
  AdminEmployeeInfo,
  DomainDataSettings,
  DomainGeneralInfo,
  DomainGoogleAnalyticsSettings,
  EmployeeEditPermissions,
  IntegrationType,
} from '@msl/settings-gateway-sdk';

// utility types
export type DeepRequired<T> = {
  [K in keyof T]-?: T[K] extends object | undefined ? DeepRequired<T[K]> : T[K];
};

export type ValidationErrorsModel<TModel> = TModel extends
  | string
  | number
  | boolean
  | undefined
  ? string
  : {
      [k in keyof TModel]+?: ValidationErrorsModel<TModel[k]>;
    };

// settings related types
export type DomainGoogleAnalyticsModel =
  DeepRequired<DomainGoogleAnalyticsSettings>;
export type DomainGoogleAnalyticsValidationErrorsModel =
  ValidationErrorsModel<DomainGoogleAnalyticsModel>;

export enum UserEntitlements {
  COMMON_TEAMS_FEATURES = 'common-teams-features',
  DOMAINS_CREATE = 'domains.create',
  DOMAIN_MANAGE = 'domain.manage',
  SETTINGS_AUTOMATIONS = 'settings.automations',
  ROLES_PERMISSIONS_ADVANCED = 'roles-and-permissions.advanced',
  EMPLOYEE_EDIT_PERMISSIONS = 'employee-edit-permissions',
  ANALYTICS = 'analytics',
  CAMPAIGNS = 'campaigns',
  SSO = 'sso',
  SHARED_INBOX = 'shared-inbox',
  MULTI_SIG = 'multi-sig',
  SERVER_SIDE_RENDERING_RULES_VIEW = 'server_side_rendering_rules.view',
  SERVER_SIDE_RENDERING_RULES_MANAGE = 'server_side_rendering_rules.manage',
  SERVER_SIDE = 'server-side',
  MICROSOFT_SECURITY_GROUP = 'microsoft-security-group',
  EMPLOYEE_HUB_BRANDING = 'employee-hub-branding',
}

export enum UserPermissions {
  ACCOUNT_MANAGE = 'account.manage',
}

export type DomainBasicInfoModel = {
  domain: string;
  organizationName: string;
  integrationType: IntegrationType;
  tz: string;
};

export type DomainBasicInfoValidationErrorsModel =
  ValidationErrorsModel<DomainBasicInfoModel>;

export type EmployeePortalSettingsModel = {
  inviteSender: string;
  emailSubject: string;
  instructions: string[];
  employeeEditPermissions: EmployeeEditPermissions;
  allowEmployeePortalBranding: boolean;
  customDomain: string;
  logoUrl: string;
  faviconUrl: string;
  brandingColor: string;
};

export type EmployeePortalSettingsErrorsModel =
  ValidationErrorsModel<EmployeePortalSettingsModel>;

export type AnalyticsSettingsModel = Record<AnalyticsFieldName, string>;

export type AnalyticsFieldName =
  | 'campaignSource'
  | 'campaignMedium'
  | 'campaignName';

export type PreviewInviteEmailDialogInput = {
  domainId: string;
};

export type DomainData = DeepRequired<DomainDataSettings>;

export type AdminEmployee = Required<AdminEmployeeInfo>;
export type GeneralDomainInfo = DeepRequired<
  Omit<DomainGeneralInfo, 'adminEmployee' | 'marketplaceLink'>
> & {
  adminEmployee?: AdminEmployee;
  marketplaceLink?: string;
};

type DomainSyncAndAutomation = {
  sync: {
    syncBySpecificators: boolean;
    syncSuspended: boolean;
    dailyEmployeeSync: boolean;
    autoDelete: boolean;
    syncAliases: boolean;
    securityGroupId: string;
    specificators: {
      values: Array<string>;
      type: string;
    };
  };
  newEmployees: {
    autoActivate: boolean;
    defaultGroup: string;
  };
  employeeActivation: {
    autoInject: boolean;
    autoInvite: boolean;
  };
};

export type SyncTask = {
  status: string;
  syncId: string;
};

export type EmailVerification = {
  status: string;
};

export type getInvitationTrustPolicy = {
  inviteEmployeesBlocked: boolean;
};

export type DomainSyncAndAutomationModel =
  DeepRequired<DomainSyncAndAutomation>;

export type Organization = {
  id?: string;
  domain?: string;
  organizationDisplayName?: string;
};
