<script setup lang="ts">
import { WsToggle, WsInput, WsButton } from '@mfl/common-components';
import strings from '../../../settings-strings';
import { computed, DeepReadonly } from 'vue';

import UploadLogoControl from './controls/upload-logo-control.vue';

import WsCard from '../../../components/card/ws-card.vue';
import WsCardSection from '../../../components/card/ws-card-section.vue';
import WsCardSectionSeparator from '../../../components/card/ws-card-section-separator.vue';
import {
  EmployeePortalSettingsModel,
  DomainData,
} from '../../../settings-types';

import { mapPropertyToSelectOption } from '../../../settings-utils';
const model = defineModel<EmployeePortalSettingsModel>({
  required: true,
});

const logoFieldOptions = computed(() => {
  return [
    {
      options: props.domainData.macros.company
        .filter((m) => m.fieldType === 'image')
        .map((m) => mapPropertyToSelectOption('company', m)),
    },
  ];
});

const props = defineProps<{
  domainData: DeepReadonly<DomainData>;
}>();

const handleBrandingToggle = (value: boolean) => {
  model.value = { ...model.value, allowEmployeePortalBranding: value };
};
</script>

<template>
  <ws-card>
    <ws-card-section>
      <template #title>{{ strings.employeePortalApplyBrandingTitle }}</template>
      <template #desc>
        {{ strings.employeePortalApplyBrandingDescription }}
      </template>
      <template #control>
        <ws-toggle
          v-model:model-value="model.allowEmployeePortalBranding"
          aid="EMPLOYEE_PORTAL_BRANDING_TOGGLE"
          @update:model-value="handleBrandingToggle"
        />
      </template>
    </ws-card-section>

    <ws-card-section-separator />

    <ws-card-section>
      <template #title>{{
        strings.employeePortalBrandingCustomDomainTitle
      }}</template>
      <template #desc>
        {{ strings.employeePortalBrandingCustomDomainDescription }}
      </template>
      <template #control>
        <ws-input
          v-model:model-value="model.customDomain"
          aid="EMPLOYEE_PORTAL_DOMAIN_INPUT"
          :disabled="!model.allowEmployeePortalBranding"
        />
      </template>
    </ws-card-section>

    <ws-card-section-separator />

    <ws-card-section>
      <template #title>{{ strings.employeePortalBrandingColorTitle }}</template>
      <template #desc>{{
        strings.employeePortalBrandingColorDescription
      }}</template>
      <template #control>
        <ws-input
          v-model:model-value="model.brandingColor"
          aid="EMPLOYEE_PORTAL_COLOR_INPUT"
          :disabled="!model.allowEmployeePortalBranding"
        />
      </template>
    </ws-card-section>

    <ws-card-section-separator />

    <ws-card-section>
      <template #title>{{
        strings.employeePortalBrandingFaviconTitle
      }}</template>
      <template #desc>{{
        strings.employeePortalBrandingFaviconDescription
      }}</template>
      <template #control>
        <ws-button
          aid="EMPLOYEE_PORTAL_FAVICON_UPLOAD"
          :label="strings.employeePortalBrandingUploadImagesBtn"
          color="primary"
          :disabled="!model.allowEmployeePortalBranding"
        />
      </template>
    </ws-card-section>

    <ws-card-section-separator />

    <ws-card-section>
      <template #title>{{ strings.employeePortalBrandingLogoTitle }}</template>
      <template #desc>{{
        strings.employeePortalBrandingLogoDescription
      }}</template>
      <template #control>
        <UploadLogoControl
          v-model="model.logoUrl"
          :options="logoFieldOptions"
          toggle-aid="EMPLOYEE_PORTAL_LOGO_TOGGLE"
          input-aid="EMPLOYEE_PORTAL_LOGO_INPUT"
          select-aid="EMPLOYEE_PORTAL_LOGO_SELECT"
          :disabled="!model.allowEmployeePortalBranding"
        />
      </template>
    </ws-card-section>
  </ws-card>
</template>
