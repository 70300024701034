<script setup lang="ts">
import { computed, onMounted } from 'vue';
import strings from '../../settings-strings';

import WsSection from '../../components/section/ws-section.vue';
import SaveButton from '../../components/button/save-button.vue';

import domainSyncAutomation from '../../composables/domain-sync-automation';
import getInvitationTrustPolicyModel from '../../composables/invitation-trust-policy-model';
import specificatorsModel from '../../composables/specificators-model';
import longActionModel from '../../composables/long-action-model';
import { STATUS } from '../../composables/specificators-model';

import domainGroups from '../../models/domain-groups';

import SyncInfo from './components/sync-info.vue';
import AutomationInfo from './components/automation-info.vue';

import generalDomainInfo from '../../composables/domain-general-info';
import { DomainSyncAndAutomationModel } from '../../settings-types';

const handleSaveChanges = () => {
  domainSyncAutomation.triggerUpdate();
};

const handleUpdateSyncSettings = (v: DomainSyncAndAutomationModel) => {
  domainSyncAutomation.onChange(v);
};

const isLoading = computed(() => {
  return (
    domainSyncAutomation.state.isLoading ||
    domainGroups.isLoading ||
    getInvitationTrustPolicyModel.isLoading
  );
});

const hasSelectedDepartments = computed(() => {
  return !!domainSyncAutomation.model.value.sync.specificators?.values?.length;
});

onMounted(async () => {
  await domainSyncAutomation.triggerFetch();
  await domainGroups.triggerFetch();
  await getInvitationTrustPolicyModel.triggerFetch();
  try {
    await specificatorsModel.triggerFetch();
    if (
      specificatorsModel.data.status &&
      specificatorsModel.data.status !== STATUS.FINISHED
    ) {
      await longActionModel.triggerFetch();
    }
  } catch (_error) {
    //ignore error
  }
});
</script>
<template>
  <ws-section :is-loading="isLoading">
    <template #header>
      {{ strings.syncAndAutomations }}
    </template>
    <template #body>
      <div class="card-title">{{ strings.sync }}</div>
      <sync-info
        v-model="domainSyncAutomation.model.value"
        :general-info="generalDomainInfo.data"
        :has-selected-departments="hasSelectedDepartments"
        @update:model-value="handleUpdateSyncSettings"
      />
      <div class="card-title-p">{{ strings.automations }}</div>
      <automation-info
        v-model="domainSyncAutomation.model.value"
        :groups-list="domainGroups.data"
        :is-integrated="generalDomainInfo.data.isIntegrated"
        :integration-type="generalDomainInfo.data.integrationType"
        @update:model-value="handleUpdateSyncSettings"
      />
    </template>
    <template #actions>
      <save-button
        aid="SAVE_SYNC_AUTOMATION_BUTTON"
        v-bind="domainSyncAutomation.state"
        @save-changes="handleSaveChanges"
      />
    </template>
  </ws-section>
</template>
