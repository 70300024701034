<script setup lang="ts">
import { computed, DeepReadonly } from 'vue';
import { capitalize as _capitalize } from 'lodash';

import WsCard from '../../../components/card/ws-card.vue';
import WsCardSection from '../../../components/card/ws-card-section.vue';
import AnalyticsFields from './controls/analytics-fields.vue';

import strings from '../../../settings-strings';

import {
  AnalyticsFieldName,
  AnalyticsSettingsModel,
  DomainData,
} from '../../../settings-types';
import { mapPropertyToSelectOption } from '../../../settings-utils';

const model = defineModel<AnalyticsSettingsModel>({
  required: true,
});

const props = defineProps<{
  isLoading: boolean;
  domainData: DeepReadonly<DomainData>;
}>();

const analyticsGroupedFields = computed(() => {
  return [
    {
      header: strings.analyticsGeneralCompanyFieldSection,
      options: props.domainData.macros.company
        .filter((m) => m.fieldType === 'text')
        .map((m) => mapPropertyToSelectOption('company', m)),
    },
    {
      header: strings.analyticsGeneralEmployeeFieldSection,
      options: props.domainData.macros.employee
        .filter((m) => m.fieldType === 'text')
        .map((m) => mapPropertyToSelectOption('employee', m)),
    },
  ];
});

const fieldsData = [
  {
    label: strings.utmSource,
    name: 'campaignSource' as AnalyticsFieldName,
    placeholder: 'e.g., newsletter, social, email',
    toggleLabel: strings.analyticsGeneralCompanyFieldToggleLabel,
    options: analyticsGroupedFields,
    toggleAid: 'CAMPAIGN_SOURCE_USE_VARIABLE',
    inputAid: 'CAMPAIGN_SOURCE_INPUT',
    selectAid: 'CAMPAIGN_SOURCE_SELECT',
  },
  {
    label: strings.utmMedium,
    name: 'campaignMedium' as AnalyticsFieldName,
    placeholder: 'e.g., summer-sale, product-launch',
    options: analyticsGroupedFields,
    toggleLabel: strings.analyticsGeneralCompanyFieldToggleLabel,
    toggleAid: 'CAMPAIGN_MEDIUM_USE_VARIABLE',
    inputAid: 'CAMPAIGN_MEDIUM_INPUT',
    selectAid: 'CAMPAIGN_MEDIUM_SELECT',
  },
  {
    label: strings.utmCampaign,
    name: 'campaignName' as AnalyticsFieldName,
    placeholder: 'e.g., email, banner, cpc',
    options: analyticsGroupedFields,
    toggleLabel: strings.analyticsGeneralCompanyFieldToggleLabel,
    toggleAid: 'CAMPAIGN_NAME_USE_VARIABLE',
    inputAid: 'CAMPAIGN_NAME_INPUT',
    selectAid: 'CAMPAIGN_NAME_SELECT',
  },
];

const handleUpdateModelValue = (v: AnalyticsSettingsModel) => {
  model.value = v;
};
</script>

<template>
  <ws-card>
    <ws-card-section>
      <template #title>{{ strings.analyticsGeneralTitle }}</template>
      <template #desc>{{ strings.analyticsGeneralDescription }}</template>
      <template #control>
        <analytics-fields
          :fields-data="fieldsData"
          :model-value="model"
          @update:model-value="handleUpdateModelValue"
        />
      </template>
    </ws-card-section>
  </ws-card>
</template>

<style lang="scss" scoped>
.analytics-general-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .analytics-general-form {
    width: 41%;
    max-width: 400px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    :deep(.analytics-general-form-field-wrapper) {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      gap: 6px;

      .analytics-general-form-field-wrapper-top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .analytics-general-form-field-wrapper-top-label {
          color: #2d2e30;
          font-family: Mulish;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 19px;
        }

        .analytics-general-form-field-wrapper-top-right-wrapper {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .analytics-general-form-field-wrapper-top-right-wrapper-label {
            color: #2d2e30;
            text-align: center;
            font-family: Mulish;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 19px;
          }
        }
      }
    }
  }
}
</style>
